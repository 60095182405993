import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import CenterContent from '../../../shared/CenterContent';
import COLORS from '../../../constants/colors';
import './ProductsView.scss';
import ProductCard from './ProductCard/ProductCard';
import { Product, ProductWithDummy } from '../../../types/product';
import MButton from '../../../shared/MButton';
import { locations } from '../../../routes/locations';
import { useFetchProductsQuery } from '../../../redux/features/products/products.apiSlice';
import {
  fetchUserPreferenceByType,
  saveUserPreference,
} from '../../../redux/modules/userPreference/userPreference.actions';
import { ReduxState, useTypedDispatch } from '../../../redux/store';
import { UserPreferenceTypeEnum } from '../../../types/userPreference';
import { fetchConfig, fetchLastSurveyResult } from '../../../store_deprecated/actions/surveyActions';
import { getConfig, getSurveyResult } from '../../../store_deprecated/selectors/surveySelectors';
import SURVEY_STATUS from '../../../constants/SurveyStatus';
import * as LocalStorage from '../../../helpers/LocalStorage';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { NewProduct } from '../../../store_deprecated/types/portfolioTypes';
import { productService } from '../../../api';
import {
  useFindProfileDataQuery,
  useLazyGetIamAuthorizationUrlQuery,
} from '../../../redux/features/auth/auth.apiSlice';
import { getSubscribedPortfoliosSelector } from '../../../store_deprecated/selectors/portfolioSelectors';
import { findSubscribedPortfolio } from '../../../store_deprecated/actions/portfolioActions';
import getDummyProducts from './Constants/ProductsConstants';
import { userPreferenceByTypeSelector } from '../../../redux/modules/userPreference/userPreference.selectors';
import { fetchStatuses } from '../../../constants/fetchStatuses';

const ProductsView: React.FC = () => {
  const { user } = useSelector(authSelector);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { data: kyc } = useFindProfileDataQuery();
  const isEnglish = i18n.language === 'en';
  const { state: routerState, pathname } = useLocation();
  const [availableProducts, setAvailableProducts] = useState<ProductWithDummy[]>([]);
  const listType = null;

  const { data: products } = useFetchProductsQuery({});
  const [getIamAuthorizationUrl] = useLazyGetIamAuthorizationUrlQuery();
  const subscribedPortfolios = useSelector(getSubscribedPortfoliosSelector);

  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [customProduct, setCustomProduct] = useState<Product>();
  const [customPortfolio, setCustomPortfolio] = useState<NewProduct>();
  const isMobile = useMediaQuery('(max-width:768px)');
  const { userPreference: productTypePreference, fetchStatus: userPreferenceFetchStatus } = useSelector(
    (state: ReduxState) => userPreferenceByTypeSelector(state, UserPreferenceTypeEnum.PRODUCT_TYPE),
  );

  const surveyResult = useSelector(getSurveyResult);

  useEffect(() => {
    if (!routerState && subscribedPortfolios?.length) {
      navigate(locations.dashboard());
    }
  }, [subscribedPortfolios, routerState]);

  useEffect(() => {
    const dummyProducts = getDummyProducts();
    setAvailableProducts(dummyProducts);
    const fetchConfiguration = async () => {
      await Promise.all([
        dispatch(fetchConfig()),
        dispatch(fetchLastSurveyResult()),
        dispatch(findSubscribedPortfolio()),
        dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.PRODUCT_TYPE)),
      ]);
    };
    fetchConfiguration();
  }, []);

  useEffect(() => {
    if (!products) {
      return;
    }

    // const subscribedProductIds = subscribedPortfolios.map((sp) => sp.productType.id);
    // const filteredProducts = products?.items.filter((p) => !subscribedProductIds.includes(p.id) && !p.isCustom);
    // const subscribedCustomProduct = subscribedPortfolios.find((sp) => sp.productType.isCustom);

    setTimeout(() => {
      // setAvailableProducts(filteredProducts);
      setAvailableProducts(products?.items?.slice(0, 4));
    }, 500);
  }, [products, subscribedPortfolios]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (userPreferenceFetchStatus === fetchStatuses.pending) {
      return;
    }

    if (productTypePreference) {
      setSelectedProduct(JSON.parse(productTypePreference));
    }
  }, [user, productTypePreference, userPreferenceFetchStatus]);

  useEffect(() => {
    if (!products?.items?.length) {
      return;
    }

    const custom = products.items.find((p: any) => p.isCustom);

    if (custom) {
      setCustomProduct(custom);
      productService
        .fetchProductPortfolio(custom?.id)
        .then(({ data }: any) => setCustomPortfolio(data?.items[0] || {}));
    }
  }, [products]);

  const saveUserPreferenceProduct = async (product: Product, portfolio: NewProduct) => {
    await dispatch(
      saveUserPreference({
        type: UserPreferenceTypeEnum.PRODUCT_TYPE,
        value: JSON.stringify(product),
      }),
    );

    // const preselected = LocalStorage.getPreselectedProduct(user!.id);

    // if (preselected?.product?.id !== product.id) {
    //   LocalStorage.setPreselectedProduct(user!.id, {
    //     custom: product.isCustom,
    //     product,
    //     portfolio,
    //     indexFunds: [],
    //     initial: true,
    //   });
    // }

    setSelectedProduct(product);
  };

  const onSurveyClick = async () => {
    if (!kyc?.id) {
      const { url } = await getIamAuthorizationUrl('en' || i18n.language).unwrap();

      if (url) {
        window.open(url, '_self');
        return;
      }

      return alert("Nafaz is disabled! Please update the KYC data in admin and change the user's status");
    }

    if (kyc?.id && !kyc?.confirmDate) {
      return navigate(locations.nafazData());
    }

    if (kyc?.id && kyc?.confirmDate && !surveyResult?.category?.id) {
      return navigate(locations.survey());
    }

    return navigate(`${locations.riskSurvey()}?page=recommendedPortfolios`, {
      state: {
        from: locations.products(),
        type: listType,
      },
    });
  };

  const onBackClick = () => {
    navigate(locations.dashboard());
  };
  const onCustomPortfolioClick = () => {
    saveUserPreferenceProduct(customProduct!, customPortfolio!);
    navigate(locations.customProduct(), {
      state: { type: listType, returnTo: pathname },
    });
  };

  const selectAndInvestHandler = async (product: Product, portfolio: NewProduct) => {
    saveUserPreferenceProduct(product, portfolio);
    onSurveyClick();
  };

  return (
    <CenterContent>
      {availableProducts ? (
        <div className={listType === 'list' ? 'products-view__list' : 'products-view'}>
          <Box
            sx={{
              width: 300,
              position: 'absolute',
              top: '3%',
              right: 0,
              textAlign: 'right',
              display: { xs: 'none', sm: 'block' },
            }}
          >
            <img src={isEnglish ? '/images/logo-frame-en.svg' : '/images/logo-frame-ar.svg'} alt="madkhol logo frame" />
          </Box>
          <div className="header">
            <img className="factory-icon" src="/images/welcome.png" alt="" />
            <div style={{ textAlign: 'center' }}>
              <Typography
                variant="h1"
                color={COLORS.BLACK}
                sx={{
                  fontWeight: { xs: 600, md: 500 },
                  fontSize: { xs: 24, md: 40 },
                  lineHeight: { xs: '24px', md: '48px' },
                  mb: isEnglish ? 2 : 4,
                }}
              >
                {t('products.title')}
              </Typography>
              <Typography
                color={listType === 'list' ? COLORS.LIGHT_GRAY : COLORS.DARK_GREY}
                sx={{ fontSize: { xs: 14, md: 18 } }}
              >
                {t('products.description')}
              </Typography>
            </div>
          </div>
          <div className={listType === 'list' ? 'product-cards__list' : 'product-cards'}>
            {availableProducts.map((product) => (
              <ProductCard
                isLoading={product.isDummy}
                key={product.id}
                product={product}
                selected={selectedProduct?.id === product.id}
                onClick={(pr, portfolio) => {
                  saveUserPreferenceProduct(pr, portfolio);
                }}
                isList={listType === 'list'}
                onSelectAndInvest={selectAndInvestHandler}
                showInvestButton
              />
            ))}
          </div>
          <div
            className={listType === 'list' ? 'custom-portfolio-btn__list' : 'custom-portfolio-btn'}
            onClick={onCustomPortfolioClick}
          >
            <img src="/images/icons/portfolio.svg" alt="madkhol-portfolio" />
            <Typography fontWeight="bold" variant="h5" sx={{ fontSize: 20 }}>
              {t('products.customBtnTitle')}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>{t('products.customBtnDescription')}</Typography>
          </div>
          <Box
            sx={{
              display: { xs: 'grid', md: 'flex' },
              gridGap: { xs: '16px', md: '32px' },
              width: { xs: '100%', md: 'auto' },
            }}
          >
            <MButton
              variant="outlined"
              onClick={onBackClick}
              typographyProps={{
                sx: {
                  padding: '16px 32px',
                  background: { xs: COLORS.WHITE, md: '#f3f4f6' },
                  border: `1px solid ${COLORS.X_DARK_BLUE}`,
                  color: COLORS.X_DARK_BLUE,
                  fontSize: 16,
                  fontWeight: 400,
                },
              }}
              buttonProps={{
                sx: {
                  backgroundColor: COLORS.TRANSPARENT,
                  backgroundImage: 'none',
                  height: '56px',
                  order: { xs: 3, md: listType === 'details' ? 1 : 0 },
                  width: { xs: '100%', md: 'auto' },
                  '&:before': {
                    background: COLORS.TRANSPARENT,
                  },
                },
              }}
            >
              <WestIcon sx={{ mr: '16px', transform: isEnglish ? 'rotate(0deg)' : 'rotate(180deg)' }} />
              {t('back')}
            </MButton>
            {!isMobile && (
              <MButton
                variant="contained"
                disabled={!selectedProduct}
                onClick={onSurveyClick}
                typographyProps={{ sx: { padding: '16px 32px', fontWeight: 700 } }}
                buttonProps={{
                  sx: {
                    order: { xs: 1, md: 2 },
                    p: 0,
                    backgroundColor: COLORS.X_DARK_BLUE,
                    backgroundImage: 'none',
                    width: { xs: '100%', md: 'auto' },
                  },
                }}
              >
                {t('products.surveyBtn')}
              </MButton>
            )}
          </Box>
        </div>
      ) : (
        <CircularProgress />
      )}
    </CenterContent>
  );
};

export default ProductsView;
